import { _CLIENTS } from '../constants/links';
import { APICore } from '../helpers/api/apiCore';

const api = new APICore();

function BLOCK_CLIENT(idClient, data) {

    const baseUrl = _CLIENTS + "block/" + idClient;
    return api.update(`${baseUrl}`, data);
}

function SEND_EMAIL(idClient, data) {

    const baseUrl = _CLIENTS + "send-email/" + idClient;
    return api.create(`${baseUrl}`, data);
}

function SEND_PUSH(idClient, data) {

    const baseUrl = _CLIENTS + "send-push/" + idClient;
    return api.create(`${baseUrl}`, data);
}

function GET_CLIENTS(idClient) {

    const baseUrl = _CLIENTS + "shop/" + idClient;
    return api.get(`${baseUrl}`);
}

function GET_CLIENTS_COUNT(idShop) {

    const baseUrl = _CLIENTS + "shop/" + idShop + "/count";
    return api.get(`${baseUrl}`);
}

function GET_CLIENTS_YEAR_MONTHS_TOTALS() {

    const baseUrl = _CLIENTS + "month-total";
    return api.get(`${baseUrl}`);
}

function GET_CLIENTS_BY_DEVICES() {

    const baseUrl = _CLIENTS + "clients-by-devices";
    return api.get(`${baseUrl}`);
}

function GET_CLIENTS_LOYALTY_POINTS() {

    const baseUrl = _CLIENTS + "loyalty-points";
    return api.get(`${baseUrl}`);
}

function GET_RESET_PASSWORD_CLIENT(token) {

    const baseUrl = _CLIENTS + "token/" + token;
    return api.get(`${baseUrl}`);
}

function RESET_CLIENT_PASSWORD(idClient, data) {

    const baseUrl = _CLIENTS + "reset-password/" + idClient;
    return api.update(`${baseUrl}`, data);
}

export { GET_CLIENTS_COUNT, BLOCK_CLIENT, SEND_EMAIL, SEND_PUSH, GET_CLIENTS_YEAR_MONTHS_TOTALS, GET_CLIENTS_BY_DEVICES, GET_CLIENTS, GET_CLIENTS_LOYALTY_POINTS, GET_RESET_PASSWORD_CLIENT, RESET_CLIENT_PASSWORD };
