import { useEffect, useState } from 'react';
import { Button, Row, Col, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FeatherIcons from 'feather-icons-react';
import { VerticalForm, FormInput } from '../../components';
import AuthLayout from './AuthLayout';
import { useSnackbar } from 'notistack';
import { RESET_PASSWORD, GET_RESET_PASSWORD_ADMIN } from '../../apis/admins';
import { CircularProgress, LinearProgress } from '@mui/material';
import { GET_RESET_PASSWORD_CLIENT, RESET_CLIENT_PASSWORD } from '../../apis/clients';

const ResetPassword = (props) => {

    useEffect(() => {
        let isClientReset = props.match?.path?.indexOf("client") !== -1
        let token = props.match?.params?.token
        if (token) {
            isClientReset ? getClient(token) : getAdmin(token)
        } else {
            setTokenExpired(true)
        }
    }, []);

    const { enqueueSnackbar } = useSnackbar()
    const [admin, setAdmin] = useState(null)
    const [client, setClient] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const [isPasswordReseted, setPasswordReseted] = useState(false)
    const [isTokenExpired, setTokenExpired] = useState(false)
    const [isUpdating, setUpdating] = useState(false)

    async function getAdmin(token) {

        let result = await GET_RESET_PASSWORD_ADMIN(token)
        if (result?.status === 200) {
            setAdmin(result.data?.admin)
        } else {
            setTokenExpired(true)
        }
        setLoading(false)
    }

    async function getClient(token) {

        let result = await GET_RESET_PASSWORD_CLIENT(token)
        if (result.status === 200) {
            setClient(result.data?.client)
        } else {
            setTokenExpired(true)
        }
        setLoading(false)
    }

    const onSubmit = async (data) => {

        let { password1, password2 } = data

        if (password1 === "" || password2 === "") {
            handleToast("Veuillez remplir tous les champs", "error")
            return
        }

        if (password1 !== password2) {
            handleToast("Mots de passe non identiques.", "error")
            return
        }

        if (password1.length < 6) {
            handleToast("Mot de passe doit contenir au moins 6 caractères", "error")
            return
        }

        setUpdating(true)
        let result = admin
            ? await RESET_PASSWORD(admin?._id, { "newPassword": password1 })
            : await RESET_CLIENT_PASSWORD(client?._id, { "newPassword": password1 })
        if (result.status === 200) {
            setPasswordReseted(true)
            if(client){ alert("Client change")}
        } else {
            handleToast(result, "error")
        }
        setUpdating(false)

    };

    function handleToast(text, type) {
        enqueueSnackbar(text, {
            variant: type,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }
        })
    }

    return (
        <>
            <AuthLayout>
                {isLoading &&
                    <CircularProgress color="primary" style={{ height: 10 }} />
                }
                {!isLoading && !isPasswordReseted && !isTokenExpired &&
                    <VerticalForm
                        onSubmit={onSubmit}
                        defaultValues={{ password1: '', password2: '' }}
                        formClass="authentication-form">
                        <h6 className="h5 mb-0 mt-3"> Réinitialiser le mot de passe</h6>
                        <p className="h5 mb-0 mt-3"></p>
                        <FormInput
                            type="password"
                            name="password1"
                            startIcon={<FeatherIcons icon={'lock'} className="icon-dual" />}
                            placeholder={'Nouveau mot de passe'}
                            containerClass={'mb-1'}>
                        </FormInput>

                        <FormInput
                            type="password"
                            name="password2"
                            startIcon={<FeatherIcons icon={'lock'} className="icon-dual" />}
                            placeholder={'Confirmer le mot de passe'}
                            containerClass={'mb-3'}>
                        </FormInput>

                        <div className="text-center d-grid">
                            <Button type="submit" disabled={isUpdating}>
                                Rénitialiser
                            </Button>
                        </div>
                        {isUpdating && <LinearProgress color="secondary" style={{ height: 5 }} />}
                    </VerticalForm>
                }

                {isPasswordReseted && !isTokenExpired &&
                    <Alert variant="success">Mot de passe renitialisé avec succès.</Alert>
                }

                {isTokenExpired &&
                    <Alert variant="danger">Lien de réinitialisation du mot de passe expiré.</Alert>
                }
            </AuthLayout>
        </>
    );
};

export default ResetPassword;